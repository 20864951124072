import "./background.css";

function Background(props) {
  return (
    <div className="background">
      <img src={props.src} />
    </div>
  );
}

export default Background;
