import "./greeting.css";

function TestFunction(props) {
  return (
    <div className="greeting">
      <p>{props.message}</p>
    </div>
  );
}

export default TestFunction;
