import { createSlice } from "@reduxjs/toolkit";

export const loadedSlice = createSlice({
  name: "loaded",
  initialState: {
    value: 0,
  },

  reducers: {
    increment: (state) => {
      state.value += 1;
      console.log(state.value);
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});

export const { increment, decrement, incrementByAmount } = loadedSlice.actions;

export default loadedSlice.reducer;
