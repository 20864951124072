import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { decrement, increment, incrementByAmount } from "redux/reducers/loaded";

import "./Home.css";
import Greeting from "components/greeting/Greeting";
import Background from "components/background/Background";
import BoxGrid from "components/layouts/boxes/BoxGrid";
import Box from "components/layouts/boxes/Box";

function App() {
  const loaded = useSelector((state) => state.loader.value);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(incrementByAmount(1));
  }, []);

  return (
    <div className="App">
      {/* <BoxGrid>
        <Box classes="box-one" text="Songs" url="/latest" />
        <Box text="Essays" classes="box-two-end" url="/collections" />
        <Box
          text="Miscellaneous | Unfinished"
          classes="box-two-start"
          url="/proposed-projects"
        />
        <Box text="About Me" classes="box-one" url="/about" />
      </BoxGrid> */}
      <Background src="../img/tyler_profile.jpg" />
      <Greeting message="Writing | Tyler Akin" />
    </div>
  );
}

export default App;
